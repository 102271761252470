import React from "react"
import { Link,graphql } from 'gatsby'

import Layout from "../../components/layout"
import Spacer from "../../components/spacer"
import Next from "../../components/next"
import Tooltip from "../../components/tooltip"

const Page = ({data}) => (
  <Layout title="Smartix" keywords={[`rail api`, `train ticket`, `api`]} submenu={data.allRailapisubmenuJson.edges}>
    <content>
      <h1>Fulfilment</h1>

      <blockquote>
        <span>It's simply quicker, cheaper and easier with <Link to="/smartix/" className="printLink">smartix</Link></span>
      </blockquote>

      <p><Link to="/smartix/" className="printLink">smartix</Link> offers both <Tooltip>ToD</Tooltip> tickets and <Tooltip>eTicket</Tooltip> and 
      can fulfil all ticket types that these media support*. We support mixed fulfilment, meaning that you can send a single fulfilment request with 
      a shopping cart containing some tickets to be fulfilled via <Tooltip>ToD</Tooltip> and some via <Tooltip>eTickets</Tooltip>. This is essential 
      until such time as <Tooltip>eTickets</Tooltip> are supported on all train journeys.</p>

      <p>With any other <Tooltip>TIS</Tooltip> you will be expected to provide a great deal of data that is generated by the journey planner. you
      will be expected to maintain that data and return it entirely unaltered in your fulfilment request. There have been so many problem with data
      being inadvertently altered (usually just formatted for display, but occasionally simply changed) that <Tooltip>RDG</Tooltip> have produced an
      entire specification detailing the time-consuming and complex testing they require any user of an API to undergo to ensure that data sent to
      fulfilment is correct.</p>

      <p>We took a different approach... We thought why should you be sent data to manage that is of no interest to you? Data that's only use is 
      during fulfilment. If we never send you that data, then there is no possibility of corruption or incorrect selection. Because we have 
      removed that possibility, that <Tooltip>RDG</Tooltip> specification no longer applies and there is less accreditation testing required before
      you can deploy a live system.</p>

    </content>

    <Spacer>
      <p>Split Ticketing Support</p>
    </Spacer>

    <content>

      <p>Whilst not everyone is interested in the significant savings that <Link to="/smartix/split-ticketing/">Split Ticketing</Link> can offer,
      many customer are. Splitting tickets means that your customer has more than one ticket for each journey. That presents its own challenges with 
      fulfilment. We have designed <Link to="/smartix/" className="printLink">smartix</Link> with support for this built in at the core. For example, 
      we have a unique <Tooltip>eTicket</Tooltip> format which greatly simplifies the task of managing a bundle of tickets.</p>

      <Next submenu={data.allRailapisubmenuJson.edges}/>

      <p class="subnote">* Currently we do not support fares requiring a sleeper berth reservation. Support for those fares will be added shortly.</p>

    </content>
  </Layout>
)

export const query = graphql`
  query {
    allRailapisubmenuJson {
      edges {
        node {
          title
          path
        }
      }
    }
  } 
`

export default Page